import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import {
  changeGridContentHeight,
  initUserAndTeam,
} from "../../../helpers/ACutils";
// import RAFFieldStateProvider from "../../../RAFComponents/Grid/RAFFieldStateProvider";
import { BrowserIsDevice } from "../../../constants/Common/Constants";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  getUTCDateValue,
  isNotNullAndUndefined,
  roundToNearestInterval,
} from "../../../RAFComponents/helpers/utils";
// import RAFReportDataList from "../../../RAFComponents/List/RAFReportDataList";
import nodata_es from "../../../assets/ES/nodata_es.svg";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { ServiceTransactionPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import {
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import { LookUpGroupRow } from "../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import { NavigateParamsProps } from "../../../router";
import ManageServiceTransaction from "../../ActiveContacts/ServiceTransaction/ServiceTransactionActions/ManageServiceTransaction";
import ManageShiftClockAction from "../../ActiveContacts/ServiceTransaction/ServiceTransactionActions/ManageShiftClockAction";
import {
  getServiceTransactionDisplayNameFromStorage,
  RetrieveServiceTransactionById,
  ScheduleFilterRow,
} from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import { UserGroupRow } from "../../ActiveContacts/UserGroup/UserGroupRow";
import { getUserTenantSettings } from "../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";
import { getClientByUID } from "../CareRecipientHelper";
import CareTaskSection from "./CareTaskSection";
import ManageCompleteShift from "./ManageCompleteShift";

interface IProps {
  relatedToType: string;
  selectedServiceTransactionId: string;
  selectedServiceTransactionRow?: ServiceTransactionRow;

  mode?: string;
  listPageGridID?: string;
  showRelatedToField?: boolean;
  refreshOnUpdateTask?: () => void;
  refreshOnDeleteTask?: () => void;
  relatedCommentsUpdated?: (relatedCommentsUpdated: boolean) => void;
  myTeams?: UserGroupRow[];
  isRelatedSection?: boolean;
  hideCareShiftLogList?: boolean;
  isDashBoard?: boolean;
  allowAdd?: boolean;

  currentViewName?: "my_shift" | "all_shift";

  scheduleFilterRow?: ScheduleFilterRow;

  shiftType?: "Billing" | "Payroll" | "Default";

  taskStatus: string[];

  selectedEntity?: string;
  isSupportLog?: boolean;

  defaultURL?: string;
  defaultSortQuery?: string[];
  emptyStateDisplayStyle?: "ImageOnly" | "TextOnly" | "Default";
  serviceTransactionAddBtn?: JSX.Element;

  onUpdateRelatedServiceTransactionItem?: () => void;
  allowSelection?: boolean;
  onDetailsClicked?: (id?: string) => void;
  islistView?: boolean;

  relatedClientUpdated?: () => void;
  careRecipientRow?: any;
  onDeleteServiceTransaction?: () => void;
  onDeletedRecordStateBackBtnClick?: () => void;
}

interface IState {
  showManageTaskContent: boolean;
  isCreateTask: boolean;
  showTaskClockActionContent: boolean;
  selectedServiceTransactionRow: ServiceTransactionRow;
  taskAction: "Clock In" | "Clock Out";
  isLoading?: boolean;
  usersAndTeams: LookUpGroupRow[];
  takeCount?: number;
  editingMode?: "Edit" | "Manage";
  careRecipientRow?: any;
  noContent: boolean;
  tenantSettings: TenantSettingsRow;
}

function CareTaskListDetails({
  shiftType = "Default",
  relatedToType = CareEsioEntity.CareRecipient.EntityName,
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  const serviceTransactionDisplayName =
    getServiceTransactionDisplayNameFromStorage();
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      showManageTaskContent: false,
      isCreateTask: false,
      showTaskClockActionContent: false,
      selectedServiceTransactionRow: null,
      taskAction: null,
      usersAndTeams: null,
      takeCount: 50,
      editingMode: "Edit",
      careRecipientRow: null,
      noContent: false,
      tenantSettings: null
    }
  );

  useEffect(() => {
    refresh();
  }, [
    props.selectedServiceTransactionRow,
    props.scheduleFilterRow,
    state.takeCount,
    props.selectedServiceTransactionId,
  ]);

  const rowClick = (rowData: Object) => {
    const taskRow: ServiceTransactionRow = rowData as ServiceTransactionRow;
    //rowActiveToggle(tableRow);
    if (isNotNullAndUndefined(taskRow)) {
      setState({ showManageTaskContent: true });
      setTimeout(() => {
        changeGridContentHeight();
      }, 200);
    }
  };

  const onEditClick = (editingMode?: "Edit" | "Manage") => {
    setState({
      isCreateTask: false,
      showManageTaskContent: true,
      editingMode,
    });
  };

  const renderCareTaskTabContent = () => {
    const { scheduleFilterRow } = props;

    const filterStateTpype = isNotNullAndUndefined(scheduleFilterRow)
      ? scheduleFilterRow.State
      : null;

    let serviceTransactionRow = state.selectedServiceTransactionRow;

    if (isNotNullAndUndefined(serviceTransactionRow)) {
      return (
        <CareTaskSection
          relatedToType={relatedToType}
          rowClick={() => rowClick(serviceTransactionRow)}
          editClick={(mode) => onEditClick(mode)}
          manageClockActionClick={(action) =>
            showTaskClockActionContent(action)
          }
          hideCareShiftLogList={props.hideCareShiftLogList}
          isDashBoard={props.isDashBoard}
          taskRow={serviceTransactionRow}
          careRecipientName={serviceTransactionRow.RelatedTo}
          careRecipientUID={serviceTransactionRow.RelatedToUID}
          currentViewName={props.currentViewName}
          shiftType={shiftType}
          selectedEntity={props.selectedEntity}
          isSupportLog={props.isSupportLog}
          isDetailsMode={true}
          isListView={props.islistView}
          onUpdateServiceTransaction={onUpdateServiceTransaction}
          careRecipientRow={state.careRecipientRow}
          showClientOverviewContent={BrowserIsDevice ? true : false}
          onSave={refreshOnUpdate.bind(this)}
          tenantSettings={state.tenantSettings}
        />
      );
    } else {
      return (
        <RAFEmptyState
          //body="Try adjusting your search or changing your filter to find what you're looking for!"
          image={nodata_es}
          title="No data available at the moment!"
          displayStyle={props.emptyStateDisplayStyle}
        ></RAFEmptyState>
      );
    }
  };

  const taskDetailsDialogClose = () => {
    setState({
      showManageTaskContent: false,
      //selectedServiceTransactionRow: null,
    });

    if (state.isCreateTask === true) {
      setState({ isCreateTask: false });
    }
    // rowActiveToggle();
    if (props.mode !== "dialog") {
      document.body.classList.remove("overflow-hidden");
    }
    let divUpdateDialog = document.getElementById("taskUpdateDialog");
    if (isNotNullAndUndefined(divUpdateDialog)) {
      divUpdateDialog.classList.remove("fade-in");
    }
    setTimeout(() => {
      changeGridContentHeight();
    }, 200);
  };

  //taskClockAction start
  const showTaskClockActionContent = (taskAction: "Clock In" | "Clock Out") => {
    setState({
      showTaskClockActionContent: true,
      taskAction,
    });
  };

  const taskClockActionContent = () => {
    if (state.showTaskClockActionContent) {
      const { selectedServiceTransactionRow, taskAction } = state;
      let minTimeValue;
      let maxTimeValue;
      let taskDateValue;
      if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
        if (selectedServiceTransactionRow.StartDate) {
          const minTime = new Date(
            getUTCDateValue(selectedServiceTransactionRow.StartDate)
          );
          if (isNotNullAndUndefined(minTime))
            minTime.setHours(minTime.getHours() - 1);
          minTimeValue = minTime;
        }

        if (selectedServiceTransactionRow.EndDate) {
          const maxTime = new Date(
            getUTCDateValue(selectedServiceTransactionRow.EndDate)
          );
          if (isNotNullAndUndefined(maxTime))
            maxTime.setHours(maxTime.getHours() + 1);
          maxTimeValue = maxTime;
        }
        taskDateValue =
          taskAction === "Clock In"
            ? selectedServiceTransactionRow.StartDate
            : selectedServiceTransactionRow.EndDate;
      }

      if (taskAction === "Clock Out") {
        return (
          <ManageCompleteShift
            selectedServiceTransactionRow={selectedServiceTransactionRow}
            shiftActivityObjectUID={null}
            onClose={taskClockActionDialogClose}
            onSave={onSaveTaskAction}
            minTimeValue={minTimeValue}
            maxTimeValue={maxTimeValue}
            taskDateValue={taskDateValue}
            isActive
          />
        );
      } else {
        return (
          <ManageShiftClockAction
            selectedServiceTransactionRow={selectedServiceTransactionRow}
            shiftActivityObjectUID={null}
            taskAction={taskAction}
            onClose={taskClockActionDialogClose}
            onSave={onSaveTaskAction}
            minTimeValue={minTimeValue}
            maxTimeValue={maxTimeValue}
            taskDateValue={null}
            isActive
          />
        );
      }
    } else {
      return <div></div>;
    }
  };

  const onSaveTaskAction = () => {
    taskClockActionDialogClose();
    refreshOnUpdate();
  };

  const taskClockActionDialogClose = () => {
    setState({
      showTaskClockActionContent: false,
      //selectedServiceTransactionRow: null,
      taskAction: null,
    });
  };
  //taskClockAction end

  const onUpdateServiceTransaction = () => {
    refreshOnUpdate();
  };

  const onTaskUpdated = () => {
    refreshGridAndLeftContent();
    taskDetailsDialogClose();
    refreshOnUpdate();
  };

  function refreshGridAndLeftContent() {
    triggerRAFEvent(RAFEventName.GridLoad, { GridId: props.listPageGridID });
    triggerRAFEvent(RAFEventName.ReloadTagMenu, null);
  }

  function taskDetailsContent() {
    if (state.showManageTaskContent) {
      const startDate = new Date();
      let newStartDate = roundToNearestInterval(startDate, 15);
      const endDate = new Date(newStartDate);
      endDate.setHours(endDate.getHours() + 2);
      const startEndDateDurationHrs = 2;
      let { selectedServiceTransactionRow } = state;
      return (
        <ManageServiceTransaction
          onDelete={props.onDeleteServiceTransaction}
          onSave={onTaskUpdated.bind(this)}
          onClose={taskDetailsDialogClose.bind(this)}
          relatedToUID={selectedServiceTransactionRow.RelatedToUID}
          relatedTo={selectedServiceTransactionRow.RelatedTo}
          relatedToType={relatedToType}
          // usersAndTeams={state.usersAndTeams}
          //startDate={newStartDate}
          //endDate={endDate}
          startEndDateDurationHrs={startEndDateDurationHrs}
          selectedTaskObject={
            state.isCreateTask ? null : state.selectedServiceTransactionRow
          }
          mode={state.isCreateTask ? "Create" : state.editingMode}
          showRelatedToLookUp={props.isDashBoard}
          setDefaultAssigneeValue={
            props.currentViewName === "my_shift" ? true : false
          }
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }

  async function refresh() {
    setState({
      isLoading: true,
      noContent: false,
      selectedServiceTransactionRow: null,
    });

    const [usersAndTeams, selectedServiceTransactionRow, tenantSettings] = await Promise.all([
      initUserAndTeam(),
      isNotNullAndUndefined(props.selectedServiceTransactionRow)
        ? props.selectedServiceTransactionRow
        : RetrieveServiceTransactionById(props.selectedServiceTransactionId),
      getUserTenantSettings(),
    ]);

    if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
      const careRecipientRow = isNotNullAndUndefined(props.careRecipientRow)
        ? props.careRecipientRow
        : await getClientByUID(selectedServiceTransactionRow.RelatedToUID);

      setState({
        selectedServiceTransactionRow,
        usersAndTeams,
        isLoading: false,
        careRecipientRow,
        tenantSettings,
        noContent: false,
      });
    } else {
      setState({
        isLoading: false,
        noContent: true,
      });
    }
  }

  const refreshOnUpdate = async () => {
    let progressDiv = showProgress("#shift_realted_task_outerDiv");
    const previousRealtedToUID =
      state.selectedServiceTransactionRow.RelatedToUID;

    setState({
      isLoading: true,
      noContent: false,
      selectedServiceTransactionRow: null,
    });

    const selectedServiceTransactionRow = await RetrieveServiceTransactionById(
      props.selectedServiceTransactionId
    );

    if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
      if (
        isNotNullAndUndefined(selectedServiceTransactionRow) &&
        selectedServiceTransactionRow.RelatedToUID !== previousRealtedToUID
      ) {
        if (props.relatedClientUpdated) {
          props.relatedClientUpdated();
        }
      }
      hideProgress(progressDiv);
      if (props.onUpdateRelatedServiceTransactionItem) {
        props.onUpdateRelatedServiceTransactionItem();
      }
      setState({ isLoading: false, selectedServiceTransactionRow });
    } else {
      if (props.onUpdateRelatedServiceTransactionItem) {
        props.onUpdateRelatedServiceTransactionItem();
      }
      hideProgress(progressDiv);
      setState({
        isLoading: false,
        noContent: true,
      });
    }
  };

  if (state.isLoading === false) {
    if (state.noContent === false) {
      return (
        <div className="position-relative h-100">
          <div className="section__container">
            <div
              // className={`${
              //   props.allowAdd === true && BrowserIsDevice
              //     ? "e-fab-btn-outer-div "
              //     : ""
              // }section__div section__div__maxHeight customScrollBar`}
              className={`section__div section__div__maxHeight customScrollBar`}
            >
              {renderCareTaskTabContent()}
            </div>
            <RAFPermissionRender
              permissionName={
                ServiceTransactionPermissionConstants.ServiceTransactionManageShifts
              }
            >
              {isNotNullAndUndefined(props.allowAdd) &&
                props.allowAdd === true && (
                  <div className="d-flex justify-content-center">
                    {props.serviceTransactionAddBtn}

                    {false && BrowserIsDevice ? (
                      props.serviceTransactionAddBtn
                    ) : (
                      // <FabComponent
                      //   id={`btn_${RAFButtonConstant.Add.Id}_${CareEsioEntity.CareShiftLog.EntityName}`}
                      //   iconCss={RAFButtonConstant.Add.IconCss}
                      //   content={RAFButtonConstant.Add.DisplayName}
                      //   onClick={createTaskClick}
                      // // target={`#care_${CareEsioEntity.CareShiftLog.EntityName}_div`}
                      // ></FabComponent>
                      <></>
                      // <RAFButtonComponent
                      //   action={RAFButtonConstant.Add}
                      //   isPrimary
                      //   className="mt-3"
                      //   onClick={createTaskClick}
                      //   uiMode="FooterBtn"
                      // />
                    )}
                  </div>
                )}
            </RAFPermissionRender>
          </div>

          <div>
            {state.showManageTaskContent === true && (
              <DialogComponent
                header={
                  state.editingMode === "Manage"
                    ? "Manage Activity"
                    : state.isCreateTask
                      ? `Add ${serviceTransactionDisplayName}`
                      : `Update ${serviceTransactionDisplayName}`
                }
                visible={state.showManageTaskContent}
                cssClass="rightDialog rightDialog-md createEditForm full-height dlg-new-style"
                id="update_task_dlg"
                content={taskDetailsContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                close={taskDetailsDialogClose.bind(this)}
                open={PreventFocusOnDialogOpen}
                showCloseIcon
                zIndex={1200}
              ></DialogComponent>
            )}
            {state.showTaskClockActionContent && (
              <DialogComponent
                header={state.taskAction}
                showCloseIcon={state.taskAction === "Clock Out" ? true : true}
                visible={state.showTaskClockActionContent}
                cssClass={
                  state.taskAction === "Clock Out"
                    ? "rightDialog createEditForm full-height dlg-new-style"
                    : "rightDialog createEditForm full-height dlg-new-style"
                  // : "centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
                }
                id="taskClockAction_dialog"
                content={taskClockActionContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                close={taskClockActionDialogClose.bind(this)}
                open={PreventFocusOnDialogOpen}
                zIndex={1200}
              ></DialogComponent>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <RAFDeletedRecordState
            title="This item is either deleted or You do not have sufficient privileges to view this item."
            showBackBtn
            onClickBackButton={props.onDeletedRecordStateBackBtnClick}
          />
        </div>
      );
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Loading..." />
      </div>
    );
  }
}

export default React.memo(CareTaskListDetails);
