import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../..";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFDateTimePicker from "../../../RAFComponents/Inputs/RAFDateTimePicker";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFLatitudeLongitude from "../../../RAFComponents/Inputs/RAFLatitudeLongitude";
import RAFNumber from "../../../RAFComponents/Inputs/RAFNumber";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showErrorToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  IsSuperAdmin,
  RetrieveRecord,
  getAllFormsByCategoryTypes,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  getSaveRequest,
  getTimeDifferenceInMinutes,
  getUTCDateValue,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFPageType } from "../../../constants/Common/RMConstants";
import { NavigateParamsProps } from "../../../router";
import {
  checkHasPermissionToStartOrCompleteShift
} from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import RAFCreate from "../../Common/Create";
import CreateContent from "../../Common/Create/CreateContent";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../Common/RAFPageLayout";
import { getUserTenantSettings } from "../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";
import EditContent from "../../Common/Update/EditContent";
import {
  SaveShift,
  ShiftActivityTitle
} from "../CareShiftLog/CareShiftLogHelper";

interface IProps {
  selectedServiceTransactionRow: ServiceTransactionRow;
  shiftActivityObjectUID: string;

  minTimeValue: Date;
  maxTimeValue: Date;
  taskDateValue: Date;
}

interface IState {
  isLoading: boolean;
  isActive: boolean;
  filteredForms: FormLibraryRow[];
  selectedSubFormLibrary: FormLibraryRow;
  selectedSubFormEntityName: string;
  shiftActivityInitialValue: any;
  showCompletionFormContent: boolean;
  completedObject: { entityId: string; objectName: string; };
  selectedCompletedForm: FormLibraryRow;
  tenantSettings: TenantSettingsRow;
}

function ManageCompleteShift({
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps & IDialogProps>) {
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  let rafShiftActivityDetailsForm: FormRenderProps | null;
  let moduleName = CareEsioEntity.CareCompleteShift.EntityName;
  let alertUtilityDialog: any;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      isActive: false,
      filteredForms: null,
      selectedSubFormLibrary: null,
      selectedSubFormEntityName: null,
      shiftActivityInitialValue: null,
      showCompletionFormContent: false,
      completedObject: null,
      selectedCompletedForm: null,
      tenantSettings: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const { isActive, shiftActivityObjectUID, selectedServiceTransactionRow } =
      props;
    if (isActive) {
      setState({ isActive: true });

      let allForms = await getAllFormsByCategoryTypes([
        CareEsioEntity.CareShiftLog.DisplayName,
        CareEsioEntity.CareRecipientCQIRegister.DisplayName,
      ]);

      let filteredForms: FormLibraryRow[] = [];
      if (isNotEmptyArray(allForms)) {
        filteredForms = allForms.filter(
          (x) =>
            x.Entity !== CareEsioEntity.ClientNote.EntityName &&
            x.Entity !== CareEsioEntity.CareCompleteShift.EntityName
          // x.Entity !== "suggestion_form" &&
          // x.Entity !== "shift_log_form" &&
          //x.Entity !== "concern_form"
        );
        let shiftActivityIndex = filteredForms.findIndex(
          (x) => x.Entity === CareEsioEntity.CareShiftActivity.EntityName
        );

        if (shiftActivityIndex !== -1) {
          let shiftActivity = filteredForms.splice(shiftActivityIndex, 1)[0];
          filteredForms.unshift(shiftActivity);
        }
      }

      let shiftActivityInitialValue;
      if (isNotNullAndUndefined(shiftActivityObjectUID)) {
        shiftActivityInitialValue = await RetrieveRecord(
          shiftActivityObjectUID,
          CareEsioEntity.CareShiftLog.EntityName
        );
      } else if (isNotNullAndUndefined(selectedServiceTransactionRow)) {
        let completeShiftForm =
          allForms &&
          allForms.find(
            (x) => x.Entity === CareEsioEntity.CareCompleteShift.EntityName
          );
        shiftActivityInitialValue = {
          care_recipientuid: selectedServiceTransactionRow.RelatedToUID,
          care_recipient: selectedServiceTransactionRow.RelatedTo,
          start_date: new Date(),
          end_date: new Date(),
          // StartDate: isNotNullAndUndefined(props.taskDateValue)
          // ? props.taskDateValue
          // : new Date(),
          // EndDate: isNotNullAndUndefined(props.taskDateValue)
          //   ? props.taskDateValue
          //   : new Date(),
          task: selectedServiceTransactionRow.Title,
          taskuid: selectedServiceTransactionRow.UID,
          form_library: completeShiftForm && completeShiftForm.Title,
          form_libraryuid: completeShiftForm && completeShiftForm.UID,
          status: RAFActionStatus.Done,
          title: ShiftActivityTitle.CompletedShift,
          description: null, //
          assignee: selectedServiceTransactionRow.Assignee,
          assigneeuid: selectedServiceTransactionRow.AssigneeUID,
        };
      }

      const tenantSettings = await getUserTenantSettings();

      setState({
        shiftActivityInitialValue,
        filteredForms,
        tenantSettings,
        isLoading: false,
      });
    } else {
      setState({ isActive: false, isLoading: false });
    }
  };

  const closeDialog = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const onSubmitShiftActivity = async (shiftActivityFormValue: any) => {
    let shiftActivityRow = R.clone(shiftActivityFormValue);

    let updatedValues = R.clone(shiftActivityRow);
    let { filteredForms } = state;
    let selectedFormLibrary =
      filteredForms &&
      filteredForms.find((x) => x.Entity === "completion_feedback");

    let { selectedSubFormEntityName, selectedSubFormLibrary } = state;
    const { selectedServiceTransactionRow } = props;

    if (isNotNullAndUndefined(selectedServiceTransactionRow) &&
      isNotNullAndUndefined(updatedValues)
    ) {
      if (isNotNullAndUndefined(selectedServiceTransactionRow.ActualStartDate)) {
        const actualStartDate: Date = new Date(selectedServiceTransactionRow.ActualStartDate);
        const actualEndDate: Date = new Date(updatedValues.end_date);
        //check if the actual start date is greater than the actual end date
        if (actualStartDate > actualEndDate) {
          showErrorToast("End time cannot be greater than start time.");
          return;
        }
      }
    }

    if (
      isNotNullAndUndefined(selectedSubFormLibrary) &&
      isNotNullAndUndefined(selectedSubFormEntityName)
    ) {
      if (
        updatedValues.AddAdditionalForm === "Yes" &&
        updatedValues.CareShiftType !== "None" &&
        updatedValues.CareShiftType === selectedSubFormLibrary.Title
      ) {
        for (let key in updatedValues) {
          if (
            key.startsWith("SubForm") &&
            key !== "SubForm" + selectedSubFormEntityName
          ) {
            delete updatedValues[key];
          }
        }
        let subFormValues =
          updatedValues["SubForm" + selectedSubFormEntityName];
        if (
          selectedSubFormLibrary.CategoryType ===
          CareEsioEntity.CareRecipientCQIRegister.DisplayName
        ) {
          if (isNotNullAndUndefined(subFormValues)) {
            subFormValues.AddToCqi = true;
            subFormValues.CareShiftType = selectedSubFormLibrary.Title;
          }
        }
      }
    }

    if (
      updatedValues.AddAdditionalForm === "No" ||
      updatedValues.CareShiftType === "None"
    ) {
      updatedValues.CareShiftType = null;
      for (let key in updatedValues) {
        if (key.includes("SubForm")) {
          delete updatedValues[key];
        }
      }
    }

    let progressDiv = showProgress(`#taskClockAction_dialog`);
    /*
    const childShiftActivityItems = await getShiftActivitiesByTaskId(
      selectedServiceTransactionRow.UID
    );

    const plannedShiftActivity = isNotEmptyArray(childShiftActivityItems)
      ? childShiftActivityItems.filter(
        (item: any) => item.Status == RAFActionStatus.Planned
      )
      : [];

    const childTaskItems = await getAllRelatedTasks(
      selectedServiceTransactionRow.RelatedToUID,
      selectedServiceTransactionRow.UID
    );
    const plannedTaskItems =
      isNotEmptyArray(childTaskItems) &&
        isNotNullAndUndefined(selectedServiceTransactionRow.RelatedToUID) &&
        isNotNullAndUndefined(selectedServiceTransactionRow.UID)
        ? childTaskItems.filter(
          (item: any) => item.TaskStatus == RAFActionStatus.Planned
        )
        : [];

    if (isNotEmptyArray(plannedShiftActivity)) {
      showAlertContent(
        "Ensure completion of one or more scheduled support activities before clocking out of this shift."
      );
      hideProgress(progressDiv);
      return;
    } else if (isNotEmptyArray(plannedTaskItems)) {
      showAlertContent(
        "Ensure completion of one or more scheduled tasks or appointments before clocking out of this shift."
      );
      hideProgress(progressDiv);
      return;
    }
    */

    let submitFormValue: ServiceTransactionRow = {};
    submitFormValue.UID = selectedServiceTransactionRow.UID;

    const taskActionDate = updatedValues.end_date;
    const actualDuration = getTimeDifferenceInMinutes(
      getUTCDateValue(selectedServiceTransactionRow.ActualStartDate),
      getUTCDateValue(updatedValues.end_date)
    );

    submitFormValue.Status = RAFActionStatus.Completed;
    if (isNotNullAndUndefined(taskActionDate)) {
      submitFormValue["ActualEndDate"] = taskActionDate;
      if (isNotNullAndUndefined(actualDuration)) {
        submitFormValue["ActualDuration"] = actualDuration;
      }
    }

    if (isNotNullAndUndefined(updatedValues)) {
      shiftActivityFormValue.start_date = updatedValues.end_date;
      submitFormValue.TravelDistance = updatedValues.travel_distance;
      if (isNullOrUndefined(props.shiftActivityObjectUID)) {
        shiftActivityFormValue.assignee =
          selectedServiceTransactionRow.Assignee ??
          msalInstance.currentUserName;
        shiftActivityFormValue.assigneeuid =
          selectedServiceTransactionRow.AssigneeUID ??
          msalInstance.currentUserId;
      }

      let saveRequestData = getSaveRequest(
        shiftActivityFormValue,
        shiftActivityFormValue.UID
      );
      saveRequestData.EntityName = moduleName;

      //commented by hari to avoid the update of service transaction row
      //const newActivity = await SaveShiftActivity(saveRequestData);

      const shiftActivity = await SaveShift(
        saveRequestData,
        "DataList/EndShift"
      );
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(shiftActivity)) {
        if (props.onSave)
          props.onSave(submitFormValue.UID, submitFormValue.Title);
        //onCompletionFormDialogOpen(selectedFormLibrary, updateTask);
      } else {
        closeDialog();
        showWarningToast(
          "Apologies, we're unable to complete the shift at the moment. Please try again later.!"
        );
      }
    }
    //commented by hari to avoid the update of service transaction row
    /*const updateTask = await updateServiceTransactionRow(submitFormValue);

    hideProgress(progressDiv);
    if (isNotNullAndUndefined(updateTask)) {
      if (props.onSave)
        props.onSave(updateTask.entityId, updateTask.objectName);
      //onCompletionFormDialogOpen(selectedFormLibrary, updateTask);
    } else {
      closeDialog();
      showWarningToast(
        "Apologies, we're unable to complete the shift at the moment. Please try again later.!"
      );
    }*/
  };

  const showAlertContent = (content: string) => {
    alertUtilityDialog = DialogUtility.alert({
      animationSettings: { effect: "Fade" },
      content: content,
      okButton: { text: "OK", click: alertOkButtonClick.bind(this) },
      title: "Alert",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      closeOnEscape: true,
      showCloseIcon: false,
    });
  };

  const alertOkButtonClick = () => {
    alertUtilityDialog.hide();
    if (props.onClose) {
      props.onClose();
    }
  };

  const setSelectedForm = (allValues) => {
    let careShiftTypeValue = allValues.CareShiftType;
    let addAdditionalForm = allValues.AddAdditionalForm;
    if (
      isNotNullAndUndefined(addAdditionalForm) &&
      addAdditionalForm === "No"
    ) {
      careShiftTypeValue = null;
    }

    let { filteredForms } = state;
    let selectedFormLibrary: FormLibraryRow;
    if (isNotNullAndUndefined(careShiftTypeValue)) {
      selectedFormLibrary = isNotNullAndUndefined(filteredForms)
        ? filteredForms.find((x) => x.Title === careShiftTypeValue)
        : null;
    }
    if (isNotNullAndUndefined(selectedFormLibrary)) {
      setState({
        selectedSubFormEntityName: selectedFormLibrary.Entity,
        selectedSubFormLibrary: selectedFormLibrary,
        //shiftActivityFormValue: updatedValues,
      });
    } else {
      setState({
        selectedSubFormEntityName: null,
        selectedSubFormLibrary: null,
        //shiftActivityFormValue: updatedValues,
      });
    }
  };
  //create shift subform start

  const onCompletionFormDialogOpen = (
    selectedCompletedForm?: FormLibraryRow,
    completedObject?: any
  ) => {
    setState({
      showCompletionFormContent: true,
      selectedCompletedForm,
      completedObject,
    });
  };

  const onSaveOrCloseCompletionForm = () => {
    setState({ showCompletionFormContent: false, selectedCompletedForm: null });
    let { completedObject } = state;
    if (props.onSave) {
      props.onSave(completedObject.entityId, completedObject.objectName);
    }
  };

  function completionFormContent() {
    if (state.showCompletionFormContent === true) {
      let initialValues = {};
      let { selectedServiceTransactionRow } = props;
      let selectedFormLibrary = state.selectedCompletedForm;

      initialValues = {
        care_recipientuid: selectedServiceTransactionRow.RelatedToUID,
        care_recipient: selectedServiceTransactionRow.RelatedTo,
        start_date: new Date(),
        end_date: new Date(),

        task: selectedServiceTransactionRow.Title,
        taskUID: selectedServiceTransactionRow.UID,
        form_library: selectedFormLibrary && selectedFormLibrary.Title,
        form_libraryUID: selectedFormLibrary && selectedFormLibrary.UID,
        status: RAFActionStatus.Done,
        title: ShiftActivityTitle.CompletedShift,

        assignee: selectedServiceTransactionRow.Assignee,
        assigneeUID: selectedServiceTransactionRow.AssigneeUID,
      };

      return (
        <RAFCreate
          onSave={onSaveOrCloseCompletionForm.bind(this)}
          onClose={onSaveOrCloseCompletionForm.bind(this)}
          isActive
          moduleName={selectedFormLibrary.Entity}
          initialValues={initialValues}
        />
      );
    } else {
      return <div></div>;
    }
  }

  const onCompletionFormDialogClose = () => {
    setState({ showCompletionFormContent: false, selectedCompletedForm: null });
  };

  //create shift sub form end

  if (state.isActive) {
    if (state.isLoading === false) {
      const { selectedServiceTransactionRow } = props;
      const assigneeUID =
        selectedServiceTransactionRow.AssigneeUID ?? msalInstance.currentUserId;
      const hasPermissionToStartOrCompleteShift =
        checkHasPermissionToStartOrCompleteShift(
          state.tenantSettings,
          selectedServiceTransactionRow.EndDate,
          "Clock Out"
        );
      if (hasPermissionToStartOrCompleteShift.hasPermissionToEdit === true) {
        return (
          <div className="h-100 bg-white">
            <div className={"dlg-new-style"}>
              <div
                className={
                  "e-dlg-body-content taskUpdateForm position-relative pb-3 mb-3"
                }
              >
                <div className="mt-0 comments-details-page">
                  <div>
                    <RAFEntityProvider moduleName={moduleName}>
                      <RAFAttributeRelatedListProvider moduleName={moduleName}>
                        <RAFAttributesContext.Consumer>
                          {({ queryAttributes }) => {
                            return (
                              <div style={{ background: "#fff" }}>
                                <RAFForm
                                  formRef={(g) =>
                                    (rafShiftActivityDetailsForm = g)
                                  }
                                  initialValues={
                                    state.shiftActivityInitialValue
                                  }
                                  layout={RAFLayout.OneColumnLayout}
                                  onSubmit={(values) =>
                                    onSubmitShiftActivity(values)
                                  }
                                  onChange={(values) => setSelectedForm(values)}
                                >
                                  <div className="row gx-2 gy-4">
                                    <RAFDateTimePicker
                                      field="end_date"
                                      label={"Out time"}
                                      showLabel
                                      showClearButton={false}
                                      roundOff={false}
                                      allowKeyboardInteraction
                                      //disableDatePicker
                                      disableDatePicker={
                                        IsSuperAdmin() ? false : true
                                      }
                                      // minDateValue={props.minTimeValue}
                                      // maxDateValue={props.maxTimeValue}
                                      //disabled
                                      disabled={IsSuperAdmin() ? false : true}
                                      required
                                    />

                                    <div className="col-md-12">
                                      {/* <RAFTextArea
                                      field="Description"
                                      label="Description"
                                      showLabel={true}
                                      placeholder="Description"
                                      rows={5}
                                      rowClassName="row g-0 gy-2"
                                    /> */}
                                      <RAFHtmlEditor
                                        field="description"
                                        label="Description"
                                        showLabel={true}
                                        placeholder="Description"
                                        rows={5}
                                        fullHeight={false}
                                        rowClassName="row g-0 gy-2"
                                      />
                                    </div>
                                    {/* <div className="row">
                                    <div
                                      className="w-100"
                                      id="actionTextAreaDiv"
                                    >
                                      <RAFTextArea
                                        field="Description"
                                        showLabel
                                        label="Description"
                                        placeholder="Description"
                                        // rows={2}
                                        formGroupClassName="m-0"
                                      />
                                    </div>
                                  </div> */}
                                    <RAFPermissionRender
                                      permissionName={
                                        ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableLocation
                                      }
                                    >
                                      <div className="col-md-12">
                                        <RAFLatitudeLongitude
                                          label="Location"
                                          field={"Latitude"}
                                          latitudeField={"locationLatitude"}
                                          longitudeField={"locationLongitude"}
                                        />
                                      </div>
                                    </RAFPermissionRender>
                                    {/* {selectedServiceTransactionRow.EnableTravel === true && (
                                    )} */}
                                    <div className="col-md-12">
                                      <RAFNumber
                                        field={"travel_distance"}
                                        label="Travel KM"
                                        placeholder={"travel km"}
                                        decimalsPoints={2}
                                      />
                                    </div>
                                    <div className="col-md-12 mt-2">
                                      {isNotNullAndUndefined(
                                        props.shiftActivityObjectUID
                                      ) ? (
                                        <EditContent
                                          moduleName={moduleName}
                                          progressDivId={`#taskClockAction_dialog`}
                                        />
                                      ) : (
                                        <CreateContent
                                          moduleName={moduleName}
                                          progressDivId={`#taskClockAction_dialog`}
                                        />
                                      )}
                                    </div>

                                    <div className="col-md-12">
                                      {isNotNullAndUndefined(
                                        state.selectedSubFormEntityName
                                      ) && (
                                          <RAFEntityProvider
                                            moduleName={
                                              state.selectedSubFormEntityName
                                            }
                                          >
                                            <RAFPageLayoutProvider
                                              prefix={
                                                "SubForm" +
                                                state.selectedSubFormEntityName
                                              }
                                              progressDivId={`#taskClockAction_dialog`}
                                              rafPageType={RAFPageType.Create}
                                            >
                                              <RAFPageLayout
                                                validateFields={true}
                                              />
                                            </RAFPageLayoutProvider>
                                          </RAFEntityProvider>
                                        )}
                                    </div>
                                  </div>
                                </RAFForm>
                              </div>
                            );
                          }}
                        </RAFAttributesContext.Consumer>
                      </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                  </div>
                </div>
              </div>

              <div className="e-dlg-footerContent">
                <div className="w-100">
                  <div className="row gx-2">
                    <div className="col-auto ms-auto">
                      <RAFButtonComponent
                        type="button"
                        isPrimary
                        action={RAFButtonConstant.Save}
                        btnContent="End Shift"
                        onClick={() =>
                          rafShiftActivityDetailsForm &&
                          rafShiftActivityDetailsForm.form.submit()
                        }
                        idString="CreateContent"
                        disabled={
                          rafShiftActivityDetailsForm &&
                          rafShiftActivityDetailsForm.submitting
                        }
                      />
                    </div>
                    <div className="col-auto">
                      <RAFButtonComponent
                        type="button"
                        action={RAFButtonConstant.Cancel}
                        onClick={props.onClose}
                        idString="CreateContent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {state.showCompletionFormContent === true && (
              <DialogComponent
                header={`Add ${state.selectedCompletedForm.Title}`}
                showCloseIcon
                visible={state.showCompletionFormContent}
                cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                id="add_subform_dlg_outerDiv"
                content={completionFormContent.bind(this)}
                isModal
                target="body"
                closeOnEscape={false}
                //close={onCompletionFormDialogClose.bind(this)}
                close={onSaveOrCloseCompletionForm.bind(this)}
              //zIndex={1210}
              ></DialogComponent>
            )}
          </div>
        );
      } else {
        return (
          <div className="e-dlg-content-outer">
            <div className="p-3 py-2 columnChooserPanel customScrollBar">
              <RAFDeletedRecordState
                title={`${hasPermissionToStartOrCompleteShift.warningMessage}`}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  }
}

export default ManageCompleteShift;
